// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {

    &:hover>.ps__thumb-y,
    &:focus>.ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
  &.ps--active-y>.ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y>.ps__rail-y,
  &.ps--scrolling-x>.ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {

  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {

  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}



/**  Invoice Print Styles **/


.invoice-container {
  /* width: 21cm; */
  margin: 0 auto;
  /* padding: 0.5cm; */
  /* border: 1px solid #ccc; */
  background: #fff;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.company-details,
.customer-details,
.invoice-details,
.product-details,
.total-details {
  margin-bottom: 20px;
}

.invoice-total-tr>td {
  text-align: right;
}

.invoice-total-tr.first {
  border-top: solid 1px black;
}

.currency {
  text-align: right;
}

.invoice-items-table>tbody>tr>td {
  border-bottom: 0;
  border-top: 0;
}

.invoice-items-table>tbody>tr>td:first-child {
  border-left: 0;
}

.invoice-items-table>tbody>tr>td:last-child {
  border-right: 0;
}

.invoice-items-table-tr-first>td {
  border-bottom: solid black 1px !important;
}

.book {
  font-weight: 600 !important;
  overflow: auto;
}

.page {
  /* min-height: 210mm; */
  padding: 1cm;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
  /* font-size: smaller; */
}

.light-title {
  font-size: small;
  text-transform: uppercase;
  font-weight: bold;
}

dl {
  margin: 0px;
}

.heading-line {
  font-size: 20px;
  margin: 10px 0px !important;
}

@media print {
  @page {
    size: 210mm 297mm;
  }
}

/* 
@page {
  size: 210mm 148mm;
}
@media print {
  @page {
    size: a5;
    margin: 0mm !important;
  }
} */

.invoice-print tr.item-row>td {
  border-bottom: solid black 1px !important;
}

.items-container {
  padding: 0px !important;
  margin: 0px !important;
}

.invoice-print table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-print th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.invoice-print th {
  background-color: #f2f2f2;
}

.total {
  font-weight: bold;
}

.invoice-print table td {
  padding: 8px;
  vertical-align: top;
  background: #fff !important;
  text-wrap: nowrap;
  font-size: 16px;
}

.invoice-print table.border>tbody>tr>td {
  border: solid black 1px !important;
}

.invoice-print table.no-border>tbody>tr>td {
  border: 0;
}

.invoice-print td.width100 {
  padding: 0 !important;
}

.header-title {
  text-transform: capitalize;
}

dd {
  margin: 0;
}

#frm-quote-create {
  table {

    th,
    td {
      padding: 5px !important;
      border: solid #d4d4d4 1px;
    }
  }

  .grand-total {
    font-size: 1.25rem;
    padding: 10px;
    font-weight: bold;
  }
}